export const terminalResponses = {
  help: "Use the terminal to get around this website. The commands will be listed for you if you type 'clist' into the text box. \n\n If you are still struggling, hover at the top of the screen to access the menu.",
  clist:
    "problems: lists all our problems available \n register: goto the register page \n login: login page \n profile: profile page \n post: post a new problem",
  weekly: "we don# have that yet mb",
  room: "MR MOORE: S006 (for less experienced programmers) \n MR STONE: B103 (for a more challenging environment)",
  times: "Every thursday lunch",

  suchir: "the goat (try disagree with me (you cant ( i made the website)))",
  "code club": "CODE CLUB ON TOP ALWAYS be there or be square",
  "about us": "iuhdsuihsd",
  nav: "the goat",
  harshith: "200k shares\n 40% tax\n 3.54 inches shorter than suchir",
  rishi: "fake chain smoker",
  arnav: "💛",
  
};
